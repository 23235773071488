<template>
	<div id="chapter">
		<BackButton ref="back" />
		<div class="title" ref="title">
			Prelude
		</div>
		<br>
		<div class="score" ref="score">
			<img src="img/ui/icon-star.svg">120 / 130
		</div>
		<PercentCircle :percent="70" :dark-mode="true" ref="percent" />
		<VideoLesson ref="video" />
		<CloudBackground />
		<div class="background">
			<div class="backgroundTop" />
			<div class="backgroundMid" />
			<div class="backgroundBot" />
		</div>
		<div class="buttons" :style="{height:btnHeight}">
			<ChapterButton
				v-for="(stage, i) in stages"
				:style="{left: (i%3)*btnSpace+'px', top:Math.floor(i/3)*btnSpace+'px'}"
				:key="stage.id"
				:disabled="!isStageUnlock(stage.id)"
				:ref="setBtnRef"
				:data-id="stage.id"
				:data="stage"
				@click="goTo(stage.type, stage.id)"
			/>
		</div>
		<div class="container">
			<TestChallenge />
			<div class="practice">
				<h3>
					Practice with a teacher
				</h3>
				<p>
					It’s time for you to not only learn to talk, but to actually talk Korean! Practice this lesson with our super teachers and improve your Korean faster!
				</p>
			</div>
			<BookNow />
			<FocusListBlock />
			<BookTeacherButton />
		</div>
	</div>
</template>

<script>

import { gsap, Quad, TimelineMax } from 'gsap/all'
import { CustomEase } from '@/lib/gsap-bonus/CustomEase'
import { CustomWiggle } from '@/lib/gsap-bonus/CustomWiggle'

import router from '@/router'
import VideoLesson from '@/components/VideoLesson.vue'
import UserData from '@/data/UserData'
import PercentCircle from '@/components/PercentCircle.vue'
import CloudBackground from '@/components/CloudBackground.vue'
import ChapterButton from '@/components/ChapterButton.vue'
import TestChallenge from '@/components/chapter/TestChallengeBlock.vue'
import BookNow from '@/components/chapter/BookNowBlock.vue'
import FocusListBlock from '@/components/chapter/FocusListBlock.vue'
import BookTeacherButton from '@/components/chapter/BookTeacherButton.vue'

// gsap scale animation
gsap.registerPlugin(CustomEase, CustomWiggle)
CustomWiggle.create('scaleEase', { type: 'uniform', wiggles: 4 })

export default {
	name: 'Chapter',
	components: {
		VideoLesson,
		PercentCircle,
		CloudBackground,
		ChapterButton,
		TestChallenge,
		BookNow,
		FocusListBlock,
		BookTeacherButton
	},

	data () {
		return {
			btnRefs: [],
			stages: null
		}
	},
	computed: {
		btnSpace () {
			return (window.innerWidth - 30) / 3
		},
		btnHeight () {
			if (this.stages == null) {
				return 0
			}
			return Math.ceil(this.stages.length / 3) * this.btnSpace + 'px'
		}
	},
	mounted () {
		fetch('/data/' + this.$route.params.id + '.json')
			.then((response) => response.json()).then((json) => {
				this.stages = json
				this.$nextTick(this.transitionIn)
			})
	},
	methods: {
		setBtnRef (el) {
			if (el) {
				this.btnRefs.push(el.$el)
			}
		},
		goTo (type, stageID) {
			if (this.isStageUnlock(stageID)) {
				router.push(`/stage/${type}/${stageID}`)
			}
		},
		transitionIn () {
			this.checkStage()
			this.$refs.video.show()
			gsap.from(this.$refs.title, { opacity: 0, x: -40 })
			gsap.from(this.$refs.score, { opacity: 0, x: -50 })
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.btnRefs, 0.5, { opacity: 0, scale: 0.8 }, {
				opacity: 1,
				scale: 1,
				ease: Quad.easeOut,
				duration: 0.5
			}, 0.03, 0,
			this.checkStageCompleted)
		},
		transitionOut (cb) {
			this.$refs.back.hide()
			this.$refs.video.hide()
			this.$refs.percent.hide()
			gsap.to(this.$refs.title, { opacity: 0, x: 40 })
			gsap.to(this.$refs.score, { opacity: 0, x: 50 })
			const tl = new TimelineMax({ delay: 0.1 })
			tl.staggerTo(this.btnRefs, 0.5, { opacity: 0, y: -20, ease: Quad.easeOut }, 0.012, '+=0', cb)
		},
		checkStageCompleted () {
			for (let i = 0; i < this.stages.length; i++) {
				const stage = this.stages[i]
				if (stage.id == UserData.lastStageFinish.value) {
					if (i == this.stages.length - 1) {
						// Chapter completed!
						console.log('chapter completed!')
						UserData.lastStageFinish.value = 'none'
						UserData.finishChapter(this.$route.params.id)
						for (const btn of this.btnRefs) {
							if (btn.dataset.id == stage.id) {
								gsap.to(btn, {
									scale: 1.1, ease: 'scaleEase', duration: 0.6, onComplete: () => {
										router.go(-1)
									}
								})
							}
						}
					} else {
						console.log('exercice completed!')
						if (UserData.unlockStage(this.stages[i + 1].id)) {
							for (const btn2 of this.btnRefs) {
								if (btn2.dataset.id == this.stages[i + 1].id) {
									gsap.to(btn2, { scale: 1.1, ease: 'scaleEase', duration: 0.6 })
								}
							}
						}
					}
				}
			}
		},
		checkStage () {
			if (!this.isStageUnlock(this.stages[0].id)) {
				UserData.unlockStage(this.stages[0].id)
			}
		},
		isStageUnlock (stageID) {
			return UserData.isStageUnlock(stageID)
		}
	},
	beforeUpdate () {
		this.btnRefs = []
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut(next)
	}
}
</script>
<style lang="stylus" scoped>
#chapter
	padding-bottom 100px
	position relative
	.practice
		margin-top 90px
		margin-bottom 40px

		h3
			font-family: NanumGothic;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.02em;
			color: #2E2E2E;

		p
			font-family: HK Grotesk Pro;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			letter-spacing: -0.03em;
			color: #828282;

	.container
		padding 0 35px
	.title
		display inline-block
		font-family: NanumGothic;
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 20px;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		color: #000;
		padding-top 20px
	.background
		position: absolute;
		left: 0px;
		top: 0px;
		z-index -1
		width: 100%;

	.backgroundTop
		height: 633.94px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #FF5379 72.88%);
	.backgroundMid
		height: 62px;
		background: #FF5379
	.backgroundBot
		height: 202px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #FF5379 72.88%);
		transform: rotate(-180deg);
	.percent
		position absolute
		top 15px
		right 15px
	.buttons
		position relative
		margin 0 30px
	.score
		display inline-block
		font-family: Neue Montreal;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 27px;
		img
			margin-right 4px
</style>
