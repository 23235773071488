<template>
	<div class="Book" @click="onClick">
		<span>
			Book a teacher
		</span>
	</div>
</template>

<script>
import router from '@/router'
import { gsap, Quad } from 'gsap/all'
export default {
	name: 'BookButton',
	mounted () {
		this.show()
	},
	methods: {
		onClick () {
			router.push('/booking')
		},
		show () {
			gsap.from(this.$el, { opacity: 0, y: 20, delay: 0.1, duration: 0.5, ease: Quad.easeOut })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, y: -20, duration: 0.5, ease: Quad.easeOut })
		}

	}
}
</script>

<style lang="stylus" scoped>
	.Book
		text-align: center;
		margin-top 50px
		span
			color: #0097FE
			font-family: 'Neue Montreal'
			font-size: 15px
			font-style: normal
			font-weight: 400
			line-height: 18px
			letter-spacing: 0em
			text-align: center
			font-size 11px
			background: #fff
			background-clip: padding-box; /* !importanté */
			border: solid 2px transparent; /* !importanté */
			border-radius: 40px;
			padding 10px 20px
			position relative

			&:before {
				content: ''
				position: absolute
				top: 0; right: 0; bottom: 0; left: 0
				background #0ff
				z-index: -1
				margin: -2px
				border-radius: inherit
				background: linear-gradient(100.57deg, rgba(0, 151, 254, 0.6), rgba(0, 151, 254, 1) )
			}
</style>
