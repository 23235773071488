<template>
	<div @click="onClick"
		class="crossBtn"
	>
		❎
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'
// import router from '@/router'

export default {
	name: 'BackButton',
	mounted () {
		gsap.set(this.$el, { x: 30 })
		this.show()
	},
	methods: {
		show () {
			gsap.to(this.$el, { opacity: 1, delay: 0.5, ease: Quad.easeOut, duration: 0.5, x: 0 })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, ease: Quad.easeOut, duration: 0.5, x: 30 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.crossBtn
	color #fff
	opacity 0
	will-change opacity
	width 20px
	height 20px
	cursor pointer
	position absolute
	top 10px
	right 10px
	&:hover
		color #FAFAFA

</style>
