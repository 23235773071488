<template>
	<div class="video" @click="getBig">
		<div class="text">
			<h2 v-if="text!=''">
				{{ text }}
			</h2>
			<h4 v-if="subtitle!=''">
				{{ subtitle }}
			</h4>
		</div>
		<img src="/img/chapters/prelude.png" class="mainImg">
		<img src="/img/ui/button-play.svg" class="play">
		<!-- Teleported -->
		<teleport to="body">
			<div v-if="modalOpen" class="modal" ref="modal">
				<CrossButton ref="close" />
				<video ref="video" loop playsinline v-if="modalOpen" @click="goSmall">
					<source src="/videos/test.mp4" type="video/mp4">
				</video>
				<div class="subtitles">
					<VideoSubtitle v-for="(s, index) in subtitles" :key="index" :left="(index%2)==0" :delay="0.3+index*0.15" :start-at="1+index*1.5" :ref="setSubtitlesRef">
						{{ s }}
					</VideoSubtitle>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script>
import { Quad } from 'gsap/gsap-core'
import { gsap } from 'gsap/all'
import { nextTick } from '@vue/runtime-core'
import VideoSubtitle from './VideoSubtitle'
import CrossButton from './CrossButton'

export default {
	name: 'VideoLesson',
	props: {
		text: {
			type: String,
			default: 'Basic Korean'
		},
		subtitle: {
			type: String,
			default: 'Watch the introduction video'
		},
		id: {
			type: String,
			default: ''
		}
	},
	components: {
		VideoSubtitle: VideoSubtitle,
		CrossButton: CrossButton
	},
	data () {
		return {
			subtitlesRefs: [],
			modalOpen: false,
			bb: null,
			subtitles: [
				'다비드 씨 안녕하세요 !',
				`잘 지내요? 저는 한국에 잘 왔어요.
여기는 정말 더워요. 파리는 날씨가 어때요?`,
				'우리 수업 이번 주에 언제 괜찮아요?',
				'좋은 주말 보내요. ',
				'수진'
			],
			currentTime: 0
		}
	},
	methods: {
		setSubtitlesRef (el) {
			if (el) {
				this.subtitlesRefs.push(el)
			}
		},
		show () {
			gsap.fromTo(this.$el, { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 0.5, ease: Quad.easeOut })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, scale: 1.1, duration: 0.5, ease: Quad.easeOut })
		},
		onVideoEnd () {
			this.goSmall()
		},
		getBig () {
			this.modalOpen = true
			nextTick(() => {
				gsap.ticker.add(this.updateVideo)
				this.$refs.video.addEventListener('ended', this.onVideoEnd)
				this.$refs.video.play()
				this.bb = this.$el.getBoundingClientRect()
				this.$refs.modal.style.width = this.bb.width + 'px'
				this.$refs.modal.style.height = this.bb.height + 'px'
				this.$refs.modal.style.top = this.bb.top + 'px'
				this.$refs.modal.style.left = this.bb.left + 'px'
				this.$refs.modal.style.opacity = 0
				gsap.to(this.$refs.modal, { opacity: 1, width: Math.min(480, window.innerWidth) + 'px', height: '100%', top: 0, x: -40, borderRadius: 0, duration: 0.7 })
			})
		},
		goSmall () {
			gsap.ticker.remove(this.updateVideo)
			this.$refs.video.removeEventListener('ended', this.onVideoEnd)
			this.$refs.close.hide()
			this.$refs.video.pause()
			gsap.to(this.$refs.modal, {
				width: this.bb.width + 'px',
				height: this.bb.height + 'px',
				top: this.bb.top + 'px',
				x: '0px',
				duration: 0.5,
				opacity: 0,
				ease: Quad.easeOut,
				onComplete: () => {
					this.modalOpen = false
				}
			})
		},
		updateVideo () {
			this.currentTime = this.$refs.video.currentTime
			for (const sub of this.subtitlesRefs) {
				sub.updateVideo(this.currentTime)
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
	video
		width calc(100% - 66px)
		height auto
	.modal
		position absolute
		overflow hidden
		text-align center
		background url('/img/lesson-video.png')
		background-repeat no-repeat
		background-size cover
		border-radius 8px
		top 0
		left 0
		bottom 0
		right 0
		background #fff
		z-index 999
	.subtitles
		margin-top 30px
	.video
		opacity 0
		will-change opacity transform
		cursor pointer
		width calc(100% - 66px)
		margin auto
		margin-top 35px
		height 170px
		position relative
		// background #0ff url('/img/chapters/prelude-red.svg')
		// background-size cover
		margin-bottom 50px
		overflow hidden
		background: linear-gradient(180deg, #FFA1B7 0%, #FF5379 100%);
		box-shadow: 0px 100px 80px rgba(255, 33, 123, 0.04), 0px 41.7776px 33.4221px rgba(255, 33, 123, 0.0503198), 0px 22.3363px 17.869px rgba(255, 33, 123, 0.0417275), 0px 12.5216px 10.0172px rgba(255, 33, 123, 0.035), 0px 6.6501px 5.32008px rgba(255, 33, 123, 0.0282725), 0px 2.76726px 2.21381px rgba(255, 33, 123, 0.0196802);
		border-radius: 20px;
		.text
			color #fff
			position absolute
			bottom 20px
			left 20px
			text-align left
			h2, h4
				padding 0
				margin 0
			h2
				font-family: NanumGothic;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: 15px;
				letter-spacing: -0.02em;
			h4
				font-family: HK Grotesk Pro;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
				letter-spacing: -0.03em;
				text-align: left;
				margin-top 5px
		.mainImg
			object-fit: cover;
			width 100%
			height 100%
			mix-blend-mode: soft-light;
			opacity 0.4
		&:after
			content ''
			position absolute
			top 0; left 0; right 0; bottom 0;
			// background: linear-gradient(270deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 100%);
			// mix-blend-mode: soft-light;
		&.hidden
			background none

		.play
			margin-top 40px
			opacity 0.9
			position absolute
			left 50%
			top 50%
			margin-left -30px
			margin-top -30px
			width 60px
			height auto

		&:hover
			.play
				opacity 1

</style>
