<template>
	<div class="button" :class="{left:left, disabled:!enabled}">
		<slot />
	</div>
</template>

<script>
import { gsap } from 'gsap/gsap-core'

export default {
	name: 'Button',
	props: {
		delay: {
			type: Number,
			default: 0
		},
		left: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: ''
		},
		startAt: {
			type: Number,
			default: 0
		}
	},
	data () {
		return {
			enabled: false
		}
	},
	mounted () {
		gsap.fromTo(this.$el, { opacity: 0, x: 30 * (this.left ? -1 : 1) }, { opacity: this.enabled ? 1 : 0.3, x: 0, delay: this.delay, duration: 1 })
	},
	watch: {
		enabled (newValue, oldValue) {
			if (newValue !== oldValue) {
				gsap.to(this.$el, { opacity: newValue ? 1 : 0.3, duration: 0.3 })
			}
		}
	},
	methods: {
		updateVideo (currentTime) {
			if (currentTime > this.startAt) {
				this.enabled = true
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
.button
	padding 20px 10px
	width 200px
	margin auto
	background #fff
	margin-bottom 40px
	border-radius: 15px
	cursor pointer
	// box-shadow: 0px 10px 12px 4px rgba(0,0,0,0.1)
	margin-right 20px
	font-size 12px
	line-height 18px
	background-color #ece0fd
	&.left
		background-color #fdedd0
		margin-left 20px

</style>
