<template>
	<div class="button" :class="{disabled:disabled, gold:isGold}">
		<div class="bonus" v-if="data.type=='bonus'">
			<img v-if="!disabled" src="img/ui/icon-bonus-gold-full.svg">
			<img v-else src="img/ui/icon-bonus-lock.svg">
		</div>
		<div class="video" v-if="data.type=='video'">
			<img v-if="!disabled" src="img/ui/icon-video-gold.svg">
			<img v-else src="img/ui/icon-video-lock.svg">
		</div>
		<div :class="{stars:!disabled, lock:disabled}" v-if="isExercice">
			<img v-if="disabled" src="img/ui/icon-lock-white.svg">
			<img v-else v-for="index in 3" :src="`img/ui/icon-star-red${score<index?'-disabled':''}.svg`" :key="index">
		</div>
	</div>
</template>

<script>
import UserData from '@/data/UserData'

export default {
	name: 'ChapterButton',
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		data: {
			type: Object,
			default: () => {}
		}
	},
	mounted () {
		// console.log(this.data.type)
	},
	computed: {
		isExercice () {
			switch (this.data.type) {
			case 'bonus':
			case 'video':
				return false
			default: return true
			}
		},
		isGold () {
			switch (this.data.type) {
			case 'bonus':
			case 'video':
				return true
			default: return false
			}
		},
		score () {
			return UserData.getScore(this.data.id)
		}
	}
}
</script>

<style lang="stylus" scoped>
.button
	width 78px
	height 78px
	background #fff
	display inline-block
	box-sizing: border-box
	border-radius: 15px
	cursor pointer
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.25) 100%);
	user-select none
	position absolute
	z-index 100
	&.gold
		background: linear-gradient(180deg, #FFE993 0%, #FED530 100%);
		box-shadow: 0px 42px 63px rgba(46, 46, 46, 0.06), 0px 12.6618px 18.9927px rgba(46, 46, 46, 0.0390953), 0px 5.25905px 7.88858px rgba(46, 46, 46, 0.03), 0px 1.9021px 2.85314px rgba(46, 46, 46, 0.0209047);
	&.disabled
		border 2px rgba(255,255,255,0.3) solid
		background transparent
	.stars, .video, .bonus, .lock
		position relative
		margin-top 26px
		margin-left 10px
		img
			position absolute
			top 0
			left 0
			z-index 10
			&:nth-child(2)
				left 13px
				z-index 9
			&:nth-child(3)
				left 26px
				z-index 8
	.video
		position relative
		margin-top 26px
		margin-left 28px
	.bonus
		position relative
		margin-top 20px
		margin-left 11px
	.lock
		margin-top 24px
		margin-left 26px

</style>
